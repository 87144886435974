import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import {
  ContentType,
  GetPurchaserPortalAuthenticatedResult,
  GetPurchaserPortalOfferSigningSessionResult,
  GetPurchaserPortalRegistrationResult,
  GetPurchaserPortalResult,
  PostPurchaserForgotPasswordBody,
  PostPurchaserPasswordResetBody,
  PostPurchaserPortalCreateOfferResult,
  PostPurchaserPortalLoginBody,
  PostPurchaserPortalLoginResponse,
  PostPurchaserPortalLoginVerifyBody,
  PostPurchaserPortalRegistrationBody,
  PostPurchaserPortalRegistrationResult,
  PostPurchaserPortalRegistrationVerificationBody,
  PostPurchaserPortalRegistrationVerificationResult,
  resendSourceType
} from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';

export class PurchaserPortalApi {
  public static async getPurchaserPortal(portalId: string, token?: string) {
    const qs = LinkBuilder.buildQueryString({
      token
    });
    return await WrappedFetch.json<GetPurchaserPortalResult>(`/purchaser/${portalId}/api/rest?${qs}`);
  }

  public static async getPurchaserPortalViewData(portalId: string) {
    return await WrappedFetch.json<GetPurchaserPortalAuthenticatedResult>(`/purchaser/${portalId}/api/rest`);
  }

  public static async login(portalId: string, body: PostPurchaserPortalLoginBody) {
    return await WrappedFetch.json<PostPurchaserPortalLoginResponse>(`/purchaser/${portalId}/api/rest/login`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  public static async logout(restApiBuilder: (url: string) => string) {
    const finalPath = restApiBuilder('/logout');
    return await WrappedFetch.bare(finalPath, {
      method: 'POST'
    });
  }

  public static async loginVerify(portalId: string, body: PostPurchaserPortalLoginVerifyBody) {
    await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/login/verification`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
    return true;
  }

  public static async resendOtp(portalId: string, username: string, source?: resendSourceType) {
    return await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/resend-otp`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify({ username, resendSource: source ?? 'login' })
    });
  }

  public static async getRegistration(portalId: string, token?: string) {
    const qs = LinkBuilder.buildQueryString({
      token
    });
    return await WrappedFetch.json<GetPurchaserPortalRegistrationResult>(`/purchaser/${portalId}/api/rest/registration?${qs}`);
  }

  /**
   * Complete purchaser details filling / password input.
   * The server will send email/phone codes as necessary.
   */
  public static async postRegistration(portalId: string, body: PostPurchaserPortalRegistrationBody) {
    return await WrappedFetch.json<PostPurchaserPortalRegistrationResult>(`/purchaser/${portalId}/api/rest/registration`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  public static async postRegistrationVerification(portalId: string, body: PostPurchaserPortalRegistrationVerificationBody) {
    return await WrappedFetch.json<PostPurchaserPortalRegistrationVerificationResult>(`/purchaser/${portalId}/api/rest/registration/verification`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  public static async postForgotPassword(portalId: string, body: PostPurchaserForgotPasswordBody) {
    await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/forgot-password`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  public static async postResetPassword(portalId: string, resetId: string, body: PostPurchaserPasswordResetBody) {
    await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/reset-password/${resetId}`, {
      headers: {
        'Content-Type': ContentType.Json
      },
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  public static async postCreateOffer(portalId: string) {
    return await WrappedFetch.json<PostPurchaserPortalCreateOfferResult>(`/purchaser/${portalId}/api/rest/offers`, {
      method: 'POST'
    });
  }

  public static async postStartSigningSession(portalId: string, offerId: string) {
    const response = await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/offers/${offerId}/signing-sessions`, {
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error(`unexpected status ${response.status}`);
    }
  }

  public static async getSigningSessionInfo(portalId: string, offerId: string, signingSessionId: string) {
    return await WrappedFetch.json<GetPurchaserPortalOfferSigningSessionResult>(`/purchaser/${portalId}/api/rest/offers/${offerId}/signing-sessions/${signingSessionId}`);
  }

  public static async postVoidSigningSession(portalId: string, offerId: string, signingSessionId: string) {
    return await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/offers/${offerId}/signing-sessions/${signingSessionId}/void`, {
      method: 'POST'
    });
  }

  public static getPurchaserPortalSubmittedDocumentUrl({
    portalId, documentId, lifecycleCopyId, fileName
  }: {
    portalId: string,
    documentId: string,
    lifecycleCopyId?: string,
    fileName: string
  }) {
    const escaped = encodeURIComponent(fileName);
    return lifecycleCopyId
      ? `/purchaser/${portalId}/api/rest/submitted-documents/${documentId}/lifecycle-copies/${lifecycleCopyId}/${escaped}`
      : `/purchaser/${portalId}/api/rest/submitted-documents/${documentId}/${escaped}`;
  }

  public static async postPurchaserPortalWithdrawOffer(portalId: string, offerId: string) {
    await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/offers/${offerId}/withdraw`, {
      method: 'POST'
    });
  }

  public static async postPurchaserPortalResubmitOffer(portalId: string, offerId: string) {
    await WrappedFetch.bare(`/purchaser/${portalId}/api/rest/offers/${offerId}/resubmit`, {
      method: 'POST'
    });
  }
}
